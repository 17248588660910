<template>
  <div>
    <c-search-box ref="searchbox" @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <!-- 사업장 -->
          <c-plant type="search" name="plantCd" v-model="searchParam.plantCd" />
        </div>
        <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-dept
            type="search"
            name="targetDeptCd"
            label="피심사부서"
            :isFirstValue="false"
            v-model="searchParam.targetDeptCd"
          ></c-dept>
        </div>
        <!-- <div class="col-xs-12 col-sm-12 col-md-3 col-lg-3 col-xl-3">
          <c-select
            type="search"
            codeGroupCd="SAI_INTERNAL_AUDIT_CLASS_CD"
            itemText="codeName"
            itemValue="code"
            label="ISO 구분"
            name="saiInternalAuditClassCd"
            v-model="searchParam.saiInternalAuditClassCd"
          ></c-select>
        </div> -->
      </template>
    </c-search-box>
    <!--체크리스트 목록-->
    <c-table
      ref="table"
      title="LBL0010131"
      tableId="checklistTable"
      :columns="grid.columns"
      :data="grid.data"
      :gridHeight="grid.height"
      :merge="grid.merge"
      :columnSetting="false"
      :isFullScreen="false"
      :usePaging="false"
      :isExcelDown="false"
      :selection="popupParam.type"
      rowKey="saiInternalAuditChecklistId"
      @rowDblclick="rowDblclick"
    >
      <!-- 버튼 영역 -->
      <template slot="table-button">
        <q-btn-group outline >
          <!--선택-->
          <c-btn label="LBLSELECT" icon="check" @btnClicked="select" />
          <!--검색-->
          <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" />
        </q-btn-group>
      </template>
      <template v-slot:customArea="{ props }">
        <template>
          <q-chip
            v-if="props.row.editFlag !== 'C'"
            outline square
            color="blue"
            :clickable="true"
            text-color="white"
            style="margin-bottom:4px !important;"
            @click.stop="getChecklist(props.row.saiInternalAuditChecklistSystemId)">
            ▶
          </q-chip>
        </template>
      </template>
    </c-table>
  </div>
</template>

<script>
import selectConfig from '@/js/selectConfig';
export default {
  name: 'internalChecklistPop',
  props: {
    popupParam: {
      type: Object,
      default: function() {
        return {
          type: 'single',
          plantCd: '',
          targetDeptCd: '',
        }
      },
    },
    contentHeight: {
      type: [String, Number],
      default: function() {
        return 'auto'
      },
    }
  },
  data() {
    return {
      searchParam: {
        plantCd: null,
        saiInternalAuditClassCd: null,
        useFlag: 'Y'
      },
      grid: {
        merge: [
          { index: 0, colName: 'saiInternalAuditChecklistSystemId' },
          // { index: 1, colName: 'saiInternalAuditChecklistSystemId' },
          { index: 1, colName: 'saiInternalAuditChecklistSystemId' },
        ],
        columns: [
          {
            name: 'plantName',
            field: 'plantName',
            label: 'LBLPLANT',
            align: 'center',
            sortable: true,
            style: 'width:80px',
          },
          // {
          //   name: 'saiInternalAuditClassName',
          //   field: 'saiInternalAuditClassName',
          //   // ISO 구분
          //   label: 'ISO 구분',
          //   align: 'center',
          //   style: 'width:80px',
          //   sortable: false,
          // },
          {
            name: 'systemElementsName',
            field: 'systemElementsName',
            //시스템요소
            label: '관련표준',
            align: 'left',
            style: 'width:150px',
            sortable: false,
          },
          // {
          //   name: 'sortOrder',
          //   field: 'sortOrder',
          //   //순서(순번)
          //   label: 'LBLSORTORDER',
          //   align: 'center',
          //   style: 'width:30px',
          //   sortable: false,
          // },
          {
            name: 'itemName',
            field: 'itemName',
            //내부심사 항목
            label: '심사항목',
            align: 'left',
            style: 'width:450px',
            sortable: true,
            type: 'html'
          },
          {
            name: 'aimMatter',
            field: 'aimMatter',
            //내부심사 시<br/>착안사항
            label: '비고',
            align: 'left',
            style: 'width:200px',
            sortable: false,
            type: 'html',
          },
        ],
        data: [],
        height: '600px'
      },
      editable: true,
      listUrl: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  watch: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sai.internal.base.checklist.list.url;
      // code setting
      // list setting
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam
      this.$http.request((_result) => {
        this.grid.data = _result.data;
      },);
    },
    select() {
      let selectData = this.$refs['table'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSG0010006', // 항목을 지정하세요.
          type: 'warning', // success / info / warning / error
        });
      } else {
        this.$emit('closePopup', selectData);
      }
    },
    rowDblclick(row) {
      if (this.popupParam.type === 'single') {
        this.$emit('closePopup', [row]);
      }
    },
  }
};
</script>
